import type { RouteRecordRaw } from 'vue-router'

const rankingRoute: RouteRecordRaw = {
  path: '/rankings',
  name: 'rankings',
  component: () => import('@/views/MainMenu.vue'),
  redirect: { name: 'player-ranking' },
  meta: {
    menuTitle: 'Ranking',
    subTitle: 'CHECK YOUR RANKING',
    banners: ['news', 'sixth-job-skills', 'classes-and-jobs'],
  },
  children: [
    {
      path: ':region?/:type?/:filter?/:filter2?',
      name: 'player-ranking',
      component: () => import('@/views/ranking/index.vue'),
      meta: {
        menuTitle: 'Player Ranking',
        seo: {
          title: 'Rankings',
          description: 'Check your ranking to compare your progress against other players!',
          keywords:
            'maplestory rankings, maplestory ranks, gms rankings, maple rankings, maplestory player rankings, maplestory leaderboards, maple world rankings',
        },
        gaPageType: 'ranking',
      },
    },
    {
      path: 'punchking',
      name: 'punchking',
      component: () => import('@/views/ranking/PunchKing.vue'),
      meta: {
        menuTitle: 'Punch King Rankings',
        fullPage: true,
        gaPageType: 'ranking - punchking',
        seo: {
          keywords:
            "maplestory punch king event, maplestory world's best punch king, maplestory punch king rankings, maplestory punch king event ranking, maplestory top ten punch king, maplestory punch king rewards, maplestory punch king winners",
        },
      },
    },
  ],
}

export default rankingRoute

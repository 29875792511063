import { useWindowSize } from '@vueuse/core'
import type { App } from 'vue'
import { VisibilityState } from './observe-visibility'
import { deepEqual } from '@/helpers/utils'

const thresholdForViewport = 0.7

export default {
  install: (app: App) => {
    const settings = (options: any) => ({
      intersection: { threshold: thresholdForViewport },
      ...options,
    })
    app.directive('observable', {
      mounted(el, { value, modifiers }) {
        const { height: winHeight } = useWindowSize()
        const { height } = useElementSize(el)
        if (modifiers.once) value = { ...value, once: true }
        el._vue_observableState_threshold = thresholdForViewport
        watchDebounced(
          [height, winHeight],
          () => {
            if (height.value > winHeight.value) {
              const newThreshold = (winHeight.value * thresholdForViewport) / height.value
              el._vue_observableState_threshold = newThreshold
              const state = el._vue_observableState
              state.createObserver(settings({ ...value, intersection: { threshold: newThreshold } }))
            }
          },
          { debounce: 100, maxWait: 400 }
        )
        el._vue_observableState = new VisibilityState(el, settings(value))
      },
      updated(el, { value, oldValue, modifiers }) {
        if (deepEqual(value, oldValue)) return
        if (modifiers.once) value = { ...value, once: true }
        const state = el._vue_observableState
        if (state) {
          state.createObserver(settings({ ...value, intersection: { threshold: el._vue_observableState_threshold } }))
        }
      },
      unmounted(el) {
        const state = el._vue_observableState

        if (state) {
          state.destroyObserver()
          delete el._vue_observableState
        }
      },
    })
  },
}

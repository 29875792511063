import { useGAEventStore } from '@/store/GAEventTracking'
import { useWindowSize } from '@vueuse/core'
import type { App } from 'vue'
import { VisibilityState } from './observe-visibility'
import { deepEqual } from '@/helpers/utils'

const thresholdForViewport = 0.5

export default {
  install: (app: App) => {
    const store = useGAEventStore()
    const settings = (el: any, value: any) => ({
      intersection: { threshold: el._vue_gaSectionViewState_threshold },
      throttle: 2000,
      once: true,
      callback: (isVisible: boolean) => {
        if (!isVisible) return
        if (typeof value === 'string') {
          // value can be string as shorthand value
          value = {
            sectionContent: value,
          }
        }
        store.pushSectionViewEvent(el, value)
      },
    })
    app.directive('ga-section-view', {
      mounted(el, { value }) {
        const { height: winHeight } = useWindowSize()
        const { height } = useElementSize(el)
        el._vue_gaSectionViewState_threshold = thresholdForViewport
        watchDebounced(
          [height, winHeight],
          () => {
            if (height.value > winHeight.value) {
              const newThreshold = (winHeight.value * thresholdForViewport) / height.value
              el._vue_gaSectionViewState_threshold = newThreshold
              const state = el._vue_gaSectionViewState
              state.createObserver(settings(el, value))
            }
          },
          { debounce: 100, maxWait: 400 }
        )

        el._vue_gaSectionViewState = new VisibilityState(el, settings(el, value))
      },
      updated(el, { value, oldValue }) {
        if (deepEqual(value, oldValue)) return
        const state = el._vue_gaSectionViewState
        if (state) {
          state.createObserver(settings(el, value))
        }
      },
      unmounted(el) {
        const state = el._vue_gaSectionViewState

        if (state) {
          state.destroyObserver()
          delete el._vue_gaSectionViewState
        }
      },
    })
  },
}
